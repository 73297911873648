import {useRef, useEffect} from "react"
import lottie from "lottie-web"

import AnimationLoading from "./loading.json"

/**
 * @param {import('lottie-web').AnimationConfigWithPath | import('lottie-web').AnimationConfigWithData } options
 */
export function useLottie(options) {
  /** @type {import("react").MutableRefObject<HTMLDivElement>} */
  const animationDiv = useRef()

  /** @type {import("react").MutableRefObject<import("lottie-web").AnimationItem>} */
  const animationInstance = useRef()

  useEffect(() => {
    animationInstance.current = lottie.loadAnimation({
      container: animationDiv.current,
      ...options,
    })
    return () => {
      animationInstance.current.destroy()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return {
    animationInstance: animationInstance.current,
    ref: animationDiv,
  }
}

export function useLoadingLottie(options) {
  return useLottie({
    renderer: "svg",
    loop: true,
    autoplay: true,
    animationData: AnimationLoading,
    ...options,
  })
}
