import * as React from "react"
import PropTypes from "prop-types"

import AnimationLoading from "../../animations/loading.json"
import {useLottie} from "../../animations/useLottie"
import "./button.scss"

/**
 * @typedef {object} ButtonOwnProps
 * @property {boolean=} block
 * @property {boolean=} loading
 * @property {boolean=} withIcon
 * @property {number=} width
 * @property {React.ReactElement=} startElement a prefix or an action to an button; For instance, an icon
 *
 * @typedef {React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>} RawButtonProps
 * @typedef {React.DetailedHTMLProps<React.AnchorHTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement>} RawAnchorProps
 * @typedef {RawButtonProps & ButtonOwnProps & RawAnchorProps} ButtonProps
 */

/**
 * @type {React.ForwardRefRenderFunction<HTMLButtonElement, ButtonProps>}
 */
export const ButtonForwardRefRender = (
  {
    className,
    block = false,
    loading,
    disabled,
    startElement,
    children,
    withIcon = false,
    width,
    style: styleFromProps,
    ...props
  },
  ref,
) => {
  const style = {
    width: block ? "100%" : width ? width : undefined,
    ...styleFromProps,
  }

  const {ref: animationRef} = useLottie({
    renderer: "svg",
    loop: true,
    autoplay: true,
    animationData: AnimationLoading,
  })

  const content = (
    <>
      <div
        hidden={!loading}
        style={{height: 60, width: 60}}
        ref={animationRef}></div>
      <div hidden={loading} className="row">
        {withIcon
          ? React.Children.map(children, child =>
              child.type
                ? React.cloneElement(child, {
                    className: `btn-icon ${child?.props?.className || ""}`,
                  })
                : child,
            )
          : children}
      </div>
    </>
  )

  return React.createElement(
    props.href ? "a" : "button",
    {
      className: `component-base-button ${className || ""} ${
        withIcon ? "with-icon" : ""
      } ${props.href ? "anchor" : ""}`,
      style,
      ref,
      ...props,
      disabled: loading || disabled,
    },
    content,
  )
}

/**
 * @type {React.ForwardRefExoticComponent<ButtonProps>}
 */
export const Button = React.forwardRef(ButtonForwardRefRender)

Button.propTypes = {
  block: PropTypes.bool,
  startElement: PropTypes.element,
}

Button.defaultProps = {
  block: false,
  loading: false,
}
