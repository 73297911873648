import * as React from "react"

import {Spacing} from "./spacing"
import IC_PASSPORT from "../../images/ic-immunity-passport.svg"
import "./logo-with-text.scss"

export const LogoWithText = () => {
  return (
    <div className="row logo-with-text-container">
      <img width={48} height={48} src={IC_PASSPORT} alt={IC_PASSPORT} />
      <Spacing spacing="l" />
      <h1>Smartworks360</h1>
    </div>
  )
}
